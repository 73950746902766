import React from 'react';
import {useState, useEffect} from "react";
import {FilterOutlined, LoadingOutlined} from '@ant-design/icons';
import {Modal, Typography, Alert, Avatar, Form, Input, Select, DatePicker, InputNumber, Slider, Col, Row } from "antd";
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlLayerTile from 'ol/layer/Tile';
import 'antd/dist/antd.css';
import './query.css'
import {Tooltip, Button, notification, Dropdown, Space  } from 'antd';
import {LoginOutlined, CheckCircleOutlined, SaveOutlined} from '@ant-design/icons';
import { UserContext } from '../UserContext';
import { filter } from 'lodash';
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo.js';

const { RangePicker } = DatePicker;

const {Text, Title} = Typography;


const FilterModal = (props) => {

    const [hover, toggleHover] = useState();
    const [visible, setVisible] = useState();
    const [units, setUnits] = useState(null)
    const [loading, setLoading] = useState(false)

    const [notUsedCols, setNotUsedCols] = useState([
        'geom', 'wkb_geometry', 'objectid', 'shape_length', 'shape_area', 'aland', 'awater',
        'website', 'lastupdated', 'name', 'acres', 'statefp', 'intptlat', 'intptlon', "countyfp" ,
        "countyns" , "geoid", "lsad", "classfp", "mtfcc", "csafp", "cbsafp", "metdivfp", "funcstat", 
        'macsid', 'notes', 'address', 'phone'
    ])
    
    const numberType = [
        'int',
        'double',
        'integer',
        'number',
        'esrifieldtypedouble',
    ]


    const translateArcGISToGeneralFields = (fields) => {
        let final = []
        fields.forEach(e => {
            final.push({
                name: e.modelName,
                type: e.type,
                label: e.alias,
                coded_values: e.domain !== null 
                    ? e.domain.codedValues.map(item => {
                        return {
                            value: item.code,
                            label: item.name
                        }
                    })
                : []
            })
        })

        return final
    }

    const translateWmsToGeneralFields = (fields) => {
        let final = []
        console.log("fields")
        console.log(fields)
        fields.forEach(e => {
            final.push({
                name: e.name,
                type: e.localType,
                label: e.name,
                coded_values: e.coded_values != undefined && e.coded_values.length > 0 ? 
                    e.coded_values.map(item => {
                        return {
                            value: item,
                            label: item
                        }
                    }) : undefined
            })
        })

        console.log("translateWmsToGeneralFields")       
        console.log(final)       

        return final
    }


    const getSetFilterOptions = async (gis_service, key, item) => {

        console.log("this.props")
        console.log(props)
        console.log("gis_service here")
        console.log(gis_service)
        console.log(item)

        if(gis_service.service_type === 'ArcGIS') {

            //get attributes
            let information = await fetch(`${gis_service.service_url}?f=pjson`)
                .then((response) => {
                    return response.json()
                })

            let filterItems = {...props.filterItems}
            let prevFilter = filterItems[key]
            let fields = translateArcGISToGeneralFields(information.fields)
            let options = createOptions(fields, prevFilter)

            let filter_options = {...props.filter_options}
            filter_options[key] = options
            props.setFilterOptions(filter_options)

        } else if(item.data_type === 'vector' && gis_service.service_type === 'GeoServer'){

            //get attribute table
            let url = `${gis_service.service_url}?SERVICE=WFS&version=1.3.0&request=DescribeFeatureType&typeNames=${gis_service.layer_name}&outputFormat=application/json`
            let response = await fetch(url)
            let data = await response.json()
            let features = data.featureTypes[0].properties

            let complete_features = []
            for (let e of features) {
                if (notUsedCols.includes(e.name) == false){
                    if (numberType.includes(e.type.toLowerCase()) == false){
                        let feature_url = `https://sparcal.sdsc.edu/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=${gis_service.layer_name}&propertyName=${e.name}`
                        let response = await fetch(feature_url)
                        let text_xml = await response.text()
                        const xmlDoc = await (new window.DOMParser()).parseFromString(text_xml, "text/xml")
                        let namespace = gis_service.layer_name.split(':')[0]

                        let element_val = xmlDoc.getElementsByTagName(namespace + ':' + e.name)
                        let element_list = Array.from(element_val)

                        let values = element_list.map(val => {
                            return val.textContent
                        })

                        let unique = [...new Set(values)]

                        unique.sort()
                        e.coded_values = unique
                    }
                    complete_features.push(e)
                }
            }

            console.log("features adfasd")
            console.log(complete_features)
            
            let filterItems = {...props.filterItems}
            let prevFilter = filterItems[key]

            let fields = translateWmsToGeneralFields(complete_features)
            let options = createOptions(fields, prevFilter)

            console.log("options here")
            console.log(options)

            let filter_options = {...props.filter_options}
            filter_options[key] = options
            props.setFilterOptions(filter_options)
            
        } else {

            let min_max = undefined
            let prevFilter = props.filterItems[key]

            console.log("prevFilter")
            console.log(prevFilter)

            // look to color map for min/max values of raster
            if(prevFilter == undefined) {
                let color_map = gis_service.color_map   
                
                if(color_map !== undefined && color_map !== null){ 
                    let color_entries = color_map.color_map_entries.map(e => e.float_value)
                    min_max ={
                        'values' : [Math.min(...color_entries),Math.max(...color_entries)]
                    }
                }
            }
            else {
                min_max = prevFilter
            }

            let var_name = 'values'
            let units = props.item.dataset_metadata.filter(e => e.name.toLowerCase().includes('units'))
            if(units.length > 0) {
                setUnits(units[0].text_value)
            }

            let fields = [
                {
                    type: 'esriFieldTypeDouble',
                    label: 'Start Value',
                    name: var_name + '_min',
                    value: min_max[var_name][0],
                    coded_values: []
                },
                {
                    type: 'esriFieldTypeDouble',
                    label: 'End Value',
                    name: var_name + '_max',
                    value: min_max[var_name][1],
                    coded_values: []
                }
            ]

            let filter_options = {...props.filter_options}
            filter_options[key] = fields
            props.setFilterOptions(filter_options)
        }
    }

    const createOptions = (fields, prevFilter) => {
        let final = []
        console.log("prevFilter")
        console.log(prevFilter)
        fields.forEach(e => {
            console.log('is number')
            console.log(e)
            console.log(e.type.toLowerCase())
            console.log(numberType.includes(e.type.toLowerCase()))
            // if the field is a number, add min and max values for range
            if(numberType.includes(e.type.toLowerCase())){

                final.push({
                    name: e.name + '_min',
                    type: e.type,
                    label: e.label,
                    placeholder: 'Start Value',
                    value: prevFilter !== undefined && prevFilter[e.name] !== undefined
                        ? prevFilter[e.name][0]
                        : undefined, 
                    // coded_values: e.coded_values
                    coded_values : undefined
                })

                final.push({
                    name: e.name + '_max',
                    type: e.type,
                    label:"</br>",
                    placeholder: 'End Value',
                    value: prevFilter !== undefined && prevFilter[e.name] !== undefined
                        ? prevFilter[e.name][1]
                        : undefined, 
                    coded_values : undefined
                    // coded_values: e.coded_values
                })
            } else {
                final.push({
                    name: e.name,
                    type: e.type,
                    label: e.label,
                    value: prevFilter !== undefined && prevFilter[e.name] !== undefined
                        ? prevFilter[e.name]
                        : undefined, 
                    coded_values: e.coded_values
                })
            }
        })


        console.log('final asdfasdaaaaaa')
        console.log(final)

        return final
    }



    const showModal = async () =>  {

        setVisible(true)
        setLoading(true)

        let gis_service = props.getGisService(props.item)
        let key = props.getItemKey(props.item)
        await getSetFilterOptions(gis_service, key, props.item)

        setLoading(false)
    }  

    const saveFilter = (values) => {

        console.log("filter_options")
        console.log(props.filter_options)
        console.log(values)

        let key = props.getItemKey(props.item)

        //filter for double variables are in two seperate fields (name_min, name_max), they need to be brought together 
        props.filter_options[key].filter(e => numberType.includes(e.type.toLowerCase()) && values[e.name] !== undefined)
            .forEach(e => {
                let is_min = e.name.endsWith('_min') ? true : false
                let original_name = e.name.slice(0, -4)

                if((original_name in values) === false){
                    values[original_name] = [0,0]
                }

                values[original_name][(is_min ? 0 : 1)] = Number(values[e.name])
    
                delete values[e.name]
            })

        // props.filter_options.filter(e => (e.type.toLowerCase() == 'string') 
        //     && values[e.name] !== undefined
        //     && values[e.name] )
        //     .forEach(e => {
        //         let value_list = values[e.name].join(',')
        //         values[e.name] = value_list
        //     })

        let itemKey = props.getItemKey(props.item)
        let filterItems = {...props.filterItems}
        filterItems[itemKey] = values

        console.log("filterItems")
        console.log(filterItems)

        props.setFilterItems(filterItems)

        setVisible(false)
        setUnits(null)
    }


    let rootStyle = props.style;
    if (rootStyle === undefined) {
        rootStyle = {
            position: 'absolute',
            // top: '196px',
            // left: '7px',
            padding: '1px 4px 1px 4px',
            borderRadius: '6px',
            backgroundColor: hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
            border: '3px solid rgba(255, 255, 255, 0.72)',
        };
    }

    let label_dic = {
        "objectid" : "Unique Identifier",
        "countyfp" : "FIPS code",
        "countyns" : "GNIS code",
        "geoid" : "County Identifier",
        "namelsad" : "County Name",
        "lsad" : "Legal/statistical Code",
        "classfp" : "FIPS class code",
        "mtfcc"	 : "MAF/TIGER feature class code",
        "csafp" : "Combined statistical area code",
        "cbsafp" : "Metropolitan area code",
        "metdivfp" : "Metropolitan division code",
        "funcstat" : "Functional status",
        "aland" : "Land area",
        "awater"  : "Water area",
        "intptlat" : "Latitude",
        "intptlon"  : "Longitude",
        "county" : "County Name",
        'fdid' : 'Fire Department Identification Number',
        'macsid' : 'MACS ID',
        'firechief' : 'Fire Chief',
        'notes' : "Extra Notes",
        'zip' : 'Zip Code',
        'address' : 'Address',
        'city' : 'City',
        'phone' : 'Phone Number',
        'calfireunit' : 'CALFIRE Unit',
        'rrk_region' : 'RRK Region'
    }

    const getLabel = (label) => {
        return label_dic[label] ? label_dic[label] : label
    }
    
    let itemKey = props.getItemKey(props.item)

    console.log("itemKey")
    console.log(itemKey)

    console.log("props.filter_options")
    console.log(props.filter_options)

    return (  <div>
                <div style={rootStyle} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
                    <Tooltip title={'Filter Map'} placement="top">
                        <FilterOutlined onClick={showModal} style={{color: 'white'}} />
                    </Tooltip>
                </div>
                <Modal title={props.item.name}
                    open={visible}
                    onCancel={() => setVisible(false)}
                    destroyOnClose={true}
                    footer={null}
                    width={800}
                    >
                    {loading ?
                        <div style={{textAlign: 'center'}}><LoadingOutlined></LoadingOutlined> Loading Filters</div>
                    : 
                        <div>
                            {units != null 
                            ? <div style={{paddingBottom: 10}}>Units: {<span dangerouslySetInnerHTML={{__html: units}}></span>}</div>
                            : null}
                            <Form
                                name="filter_modal"
                                layout="vertical"
                                onFinish={saveFilter}
                                preserve={true}
                                autoComplete="off"
                                fields={props.filter_options[itemKey]}
                                scrollToFirstError="true">
                                <Row>
                                {(Object.keys(props.filter_options).includes(itemKey) ? props.filter_options[itemKey].map((option, i) => (
                                    option.coded_values && option.coded_values.length > 0
                                    ? <Col span={12} style={{paddingRight: 20}}>
                                        <Form.Item  
                                        name={option.name}
                                        label={<span dangerouslySetInnerHTML={{__html: getLabel(option.label)}}></span>}
                                        id={option.name}
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                placeholder="- SELECT -"
                                                options={option.coded_values}
                                            />
                                        </Form.Item>
                                    </Col>
                                    : option.type.toLowerCase().includes('date')
                                    ? <Col span={12} style={{paddingRight: 20}}>
                                        <Form.Item 
                                            name={option.name}
                                            label={<span dangerouslySetInnerHTML={{__html: getLabel(option.label)}}></span>}>
                                            <RangePicker />
                                        </Form.Item>
                                    </Col>                            
                                    : numberType.includes(option.type.toLowerCase())
                                    ?                                                                 
                                        <Col span={6} style={{paddingRight: 20}}>
                                        <Row>
                                            <Col span={24} style={{paddingBottom: 8}}>{<span dangerouslySetInnerHTML={{__html: getLabel(option.label)}}></span>}</Col>
                                            <Col span={24}>
                                                <Form.Item 
                                                    name={option.name}>
                                                    <InputNumber style={{width:'90%'}} placeholder={option.placeholder}/>
                                                </Form.Item>                                   
                                            </Col>
                                        </Row>
                                        </Col>
                                    : option.type.toLowerCase().includes('string')
                                    ? <Col span={12} style={{paddingRight: 20}}>
                                        <Form.Item 
                                            name={option.name}
                                            label={<span dangerouslySetInnerHTML={{__html: getLabel(option.label)}}></span>}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    : null
                                )) : [])}
                                <Col span={24}><div style={{'fontSize': 10}}>*All filter value ranges are inclusive.</div></Col>
                                <Col span={24} align='middle' style={{marginTop: 10, marginBottom: 10}}>
                                    <Form.Item>
                                        <Button type="primary" style={{borderRadius: 5}} size={'middle'} htmlType="submit">Filter</Button>
                                    </Form.Item>
                                </Col>                        
                                </Row>
                            </Form>
                        </div>
                }
            </Modal>
        </div>
        )
    }


export default FilterModal;


