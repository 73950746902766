import React from 'react';
import {useState, useEffect, useContext} from "react";
import {FilterOutlined, LoadingOutlinSpined} from '@ant-design/icons';
import {Modal, Typography, Alert, Avatar, Form, Input, Button, Dropdown, List, Tooltip, Spin } from "antd";
import 'antd/dist/antd.css';
import './query.css'
import { Space, Table, Tag } from 'antd';
import {LoginOutlined, ImportOutlined, QuestionCircleOutlined, AppstoreOutlined, DeleteOutlined} from '@ant-design/icons';
import { UserContext } from '../UserContext';
import WorkspaceContext from '../WorkspaceContext';
import moment from 'moment';

const {Text} = Typography;
const { Column } = Table;
const syncfetch = require('sync-fetch')


class ForestDataMapWorkspaces extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            visible : false,
            delete_visible: false,
            selectedWorkspaceId: null,
            workspaceList: [],
            loading: false
        };

        this.setUserIdHere = this.setUserIdHere.bind(this)
        this.toggleHover = this.toggleHover.bind(this);
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.showModal.bind(this)
        this.populateWorkspaceTable = this.populateWorkspaceTable.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this);
        this.getCollectionAndDatasetInfo = this.getCollectionAndDatasetInfo.bind(this)
        this.translateWorkspaceData = this.translateWorkspaceData.bind(this)
        this.getWorkspaceFiltersAndChecked = this.getWorkspaceFiltersAndChecked.bind(this)
    }

    static contextType = WorkspaceContext

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    showModal() {
        this.setState({visible: true})
    }

    hideModal() {
        this.setState({visible: false})
    }

    showDeleteModal(record) {
        this.setState({delete_visible: true})
        this.setState({'selectedWorkspaceId': record.workspace_id})
        this.setState({'selectedWorkspaceName': record.workspace_name})
    }

    hideDeleteModal() {
        this.setState({delete_visible: false})
    }

    onFinishFailed = (errorInfo) => {
       this.setState({error: true})
    };

    setUserIdHere(userId){
        this.setState({loginUser: userId})
    }

    async getCollectionAndDatasetInfo(base_url, access_token, collection_id, dataset_ids){
        const collection = await fetch(`${base_url}/DatasetCollection/${collection_id}`, 
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + access_token
            }
        })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));

        let dataset_names = []
        let dataset_layer = []

        for (let i = 0; i < dataset_ids.length; i++) {
            let dataset_id = dataset_ids[i]
            const dataset = await fetch(`${base_url}/Dataset/${dataset_id}`, 
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + access_token
                }
            })
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));

            dataset_names.push(dataset.name)
            dataset_layer.push(dataset)
        }

        return { 'collection_name': collection.name, 'dataset_names': dataset_names, 'dataset_layers': dataset_layer}
    }


    async getWorkspaceFiltersAndChecked(info, collection_data){
        let filters = {}
        let checked_dataset = {}
        let opacities = {}

        let dataset_id_key = {}

        for(let i = 0; i < collection_data.length; i++){
            let data = collection_data[i]
            if(data.dataset_layers.length > 0){
                for(let i = 0; i < data.dataset_layers.length; i++){
                    let layer = data.dataset_layers[i]
                    let key = this.props.getItemKey(layer)
                    dataset_id_key[layer.dataset_id] = key
                }
            }
        }

        for (let i = 0; i < info.length; i++) {
            let datasetFilter = info[i]
            let key = dataset_id_key[datasetFilter['dataset_id']]
            if(datasetFilter.filters !== undefined){

                //if filter is date, change from string type to Moment object type 
                //all dates are in a date range
                Object.keys(datasetFilter.filters).filter(key => Array.isArray(datasetFilter.filters[key])).forEach(key => {
                    
                    datasetFilter.filters[key].forEach((val, idx) => {
                        let potentialDate = new Date(val)
                        if(potentialDate instanceof Date && !isNaN(potentialDate) && typeof val == 'string'){
                            datasetFilter.filters[key][idx] = moment(potentialDate)
                        }
                    })
                })

                filters[key] = datasetFilter.filters
            }
            if(datasetFilter.is_checked !== undefined){
                checked_dataset[key] = datasetFilter.is_checked
            }

            if(datasetFilter.opacity !== undefined){
                opacities[key] = datasetFilter.opacity
            }else {
                opacities[key] = 0.7
            }
        }

        return [filters, checked_dataset, opacities]
    }

    async translateWorkspaceData(results, base_url, access_token) {
        let workspaces = []

        for (let i = 0; i < results.length; i++) {
            let workspace = results[i]
            let final = {}
            final['workspace_id'] = workspace.workspace_id
            final['workspace_name'] = workspace.workspace_name
            final['created_on'] = workspace.created_on
            final['last_used_date'] = workspace.last_used_date

            let workspace_info = await JSON.parse(workspace.workspace_info)

            let collection_dataset_list = {}
            for (let i = 0; i < workspace_info.datasetInformation.length; i++) {
                let info = workspace_info.datasetInformation[i]
                let prevDatasets = collection_dataset_list[info.collection_id] ?? []
                prevDatasets.push(info.dataset_id)
                collection_dataset_list[info.collection_id] = prevDatasets
            }

            let collection_data = []
            for (let i = 0; i < Object.keys(collection_dataset_list).length; i++) {
                let collection_id = Object.keys(collection_dataset_list)[i]
                let dataset_ids = collection_dataset_list[collection_id]

                let data = await this.getCollectionAndDatasetInfo(base_url, access_token, collection_id, dataset_ids)
                collection_data.push(data)
            }

            final['workspace_info'] = collection_data

            let [filters, checked_dataset, opacities] = await this.getWorkspaceFiltersAndChecked(workspace_info.datasetInformation, collection_data)

            console.log("filters")
            console.log(filters)

            final['filters'] = filters
            final['checked_dataset'] = checked_dataset
            final['opacities'] = opacities
            
            workspaces.push(final)
        }

        return workspaces
    }


    async populateWorkspaceTable(props)
    {
        this.setState({"visible" : true})
        this.setState({"loading" : true })

        let base_url = process.env.REACT_APP_API_URL

        const response = await fetch(`${base_url}/workspace/read_workspaces_by_user`, 
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + props.access_token
                }
            })
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));

        let results = JSON.parse(response['results'])

        let workspaces = await this.translateWorkspaceData(results, base_url, props.access_token)

        this.setState({'workspaceList': workspaces})
        this.setState({"loading" : false })
    }
    
    async loadWorkspace(record, props){

        let newSelectedDatasets = []

        console.log("record")
        console.log(record)
        
        record.workspace_info.forEach(data => {
            let layers = data.dataset_layers
            newSelectedDatasets.push(...layers)
        })

        this.context.setSelectedDatasets(newSelectedDatasets)

        console.log("record.checked_dataset")
        console.log(record)
        console.log(record.opacities)

        console.log('this.props this.props')
        console.log(this.props)

        this.props.setCheckedDatasets(record.checked_dataset)
        this.props.setFilterItems(record.filters)
        this.props.setOpacities(record.opacities)

        this.setState({"visible" : false})
    }

    async deleteWorkspace(workspaceId, props){

        console.log('workspaceId')
        console.log(workspaceId)

        let base_url = process.env.REACT_APP_API_URL

        const response = await fetch(`${base_url}/workspace/workspace/${workspaceId}`, 
        {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + props.access_token
            },
            body: JSON.stringify({
                'workspaceId' : workspaceId
            })
        })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));

        this.populateWorkspaceTable(props)

        this.hideDeleteModal()
    }

    render() {

        let iconStyle = {
            paddingRight: "5px"
        }

        console.log("this.state.workspaceList")
        console.log(this.state.workspaceList)

        let columns = [ 
            {
                title: 'Name',
                dataIndex: 'workspace_name',
                key: 'workspace_name',       
                sorter: (a, b) => a.workspace_name.localeCompare(b.workspace_name)
            },
            {
                title: () => <div><span style={{"marginRight": "10px"}}>Data Sources</span></div>,
                key: 'dataset',
                render: (_, record ) => (
                    <div style={{"paddingLeft": "10px"}}>
                        <ul style={{"paddingLeft": "0px"}}>
                            {record.workspace_info.map((data, i) => {
                                return <li key={`data_source_${i}`}>
                                    {data.collection_name}
                                    <ul>
                                        {data.dataset_names.map(name => {
                                            return <li><strong>{name}</strong></li>
                                        })}
                                    </ul>
                                </li>
                                }
                            )}
                        </ul>
                    </div>
                )
            },
            {
                title: () => <div><span style={{"marginRight": "10px"}}>Last Used</span></div>,
                dataIndex: 'last_used_date',
                key: 'last_used_date',
                render: (_, record ) => (
                    <span>{new Date(record.last_used_date).toLocaleDateString('en-US')}</span>
                )
            },
            {
                title: () => <div><span style={{"marginRight": "10px"}}>Created On</span></div>,
                dataIndex: 'created_on',
                key: 'created_on',
                render: (_, record ) => (
                    <span>{new Date(record.created_on).toLocaleDateString('en-US')}</span>
                )
            },
            {
                title: 'Actions',
                dataIndex: 'key',
                key: 'key',
                render: (text, record) => (
                    <div>
                        <div>
                            <Tooltip title="Load">
                                <Button onClick={()=> this.loadWorkspace(record, this.props)}  style={{marginBottom: '5px'}}
                                type="primary" size="small" icon={<ImportOutlined />}></Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Delete">
                                <Button onClick={()=> this.showDeleteModal(record)} type="danger" size="small" icon={<DeleteOutlined />}></Button>
                            </Tooltip>
                        </div>
                    </div>
                   ),
            }
        ]

        let rootStyle = this.props.style;
        if (rootStyle === undefined) {
            rootStyle = {
                position: 'absolute',
                top: '196px',
                left: '7px',
                padding: '1px 4px 1px 4px',
                borderRadius: '6px',
                backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
                border: '3px solid rgba(255, 255, 255, 0.72)',
            };
        }

       return ( 
        <div>
            <div>
                <a onClick={() => this.populateWorkspaceTable(this.props)} style={{color: 'black'}}>
                    <AppstoreOutlined style={{paddingRight: "5px"}}></AppstoreOutlined>Workspaces
                </a>
            </div>
            <Modal title="Workspaces"
                open={this.state.visible}
                onCancel={() => this.setState({"visible" : false})}
                footer={null}
                width={1100}
                style={
                    {
                        maxHeight: "300px"
                    }
                }
                >
                <div>
                    {this.state.loading 
                    ? <span style={{textAlign: 'center'}}><Spin></Spin> <span style={{paddingLeft: 10}}>Loading Workspaces</span></span>
                    : 
                        <Table dataSource={this.state.workspaceList} columns={columns}
                            pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30']}}
                            >
                            {/* <Column title='Workspace Name' dataIndex='workspace_name' key='workspace_name'/>
                            <Column title="Datasets" dataIndex="all_datasets" key="all_datasets"/>
                            <Column title="Filtered Datasets" dataIndex="value_ranges" key="value_ranges" />
                            <Column title="Date Last Used" dataIndex="last_used_date" key="last_used_date"
                                render={(date) => {
                                    <Space></Space>
                                }} />
                            <Column title="Action" key='action'>
                            </Column> */}
                        </Table>
                    }
                </div>
            </Modal>
            <Modal 
                open={this.state.delete_visible}
                onCancel={() => this.setState({"delete_visible" : false, "selectedWorkspaceId" : null})}
                onOk={() => this.deleteWorkspace(this.state.selectedWorkspaceId, this.props)}
                okText="Delete"
                okType='danger'
                cancelText="Cancel"
            >
                <div>
                    Are you sure you want to delete {this.state.selectedWorkspaceName} workspace? 
                </div>
            </Modal>
        </div>
    )}

}

export default ForestDataMapWorkspaces;
