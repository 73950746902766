import {Modal} from "antd";

const download_shape = async (dataset, setLoading) => {
    console.log("download shape here")
    let layername = dataset.gis_services[0].layer_name
    let layer = layername.split(":")[1]
    let workspace = layername.split(':')[0]

    let url = `https://sparcal.sdsc.edu/geoserver/${workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${encodeURIComponent(layername)}&maxFeatures=50&outputFormat=shape-zip`
    console.log(url)

    await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain',
        }
    }).then((transfer) => {
        return transfer.blob();
    }).then((bytes) => {
        let elm = document.createElement('a');
        elm.href = URL.createObjectURL(bytes);
        elm.setAttribute('download', `${layer}.zip`);
        elm.click();
        setLoading(false);
    }).catch((error) => {
        setLoading(false);
        Modal.error({
            title: 'Error',
            content: error.message
        });
    })
   
    setLoading(false);

}

export default download_shape;