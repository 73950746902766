import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';
// import {process} from 'react-scripts@5.0.1'
import { Tooltip, notification, Modal, Form, Input, Button, Spin, Checkbox } from 'antd';
import OlOverlay from 'ol/Overlay';
import './forest_data_map_layer_popover.css';
import { getLayerConfig } from './utils';
import config from './config';
import { json } from 'react-router-dom';
import axios, * as others from 'axios';
import downloadjs from "downloadjs";



class ForestDataMapDownload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feature_attributes: [],
            hover: false, 
            visible: false,
            download_loading: false,
            error: undefined
        }
        this.onDownload = this.onDownload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleHover = this.toggleHover.bind(this)
    }

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }


    handleClose(event) {
        this.popup.setPosition(undefined);
        event.target.blur();
        return false;
    }

    openModal(){
        this.setState({visible: true})
    }

    closeModal(){
        this.setState({visible: false})
        this.setState({download_loading: false})
    }

    // makeRequest(o){
    //   var xhr = new XMLHttpRequest(),
    //       h = new WebView.Handler(),
    //       path, type, req, binary;
      
    //   if(typeof o === 'string') {
    //       path = o;
    //   } else {
    //       path = o.path;
    //       type = o.type;
    //       req = o.args;
    //       binary = o.binary;
    //   }

    //   if(!type) {
    //       type = 'GET';
    //   }
      
    //   xhr.onreadystatechange = function() {
    //       //console.log('rs = ' + xhr.readyState + ' status = ' + xhr.status + ' responseText = ' + xhr.responseText);
    //       var d;
    //       //console.log(xhr);
    //       if(xhr.readyState === 4) {
    //           if(xhr.status === 200) {
    //               h._handleAlways();
    //               if(binary) {
    //                   d = xhr.response;
    //               } else {
    //                   d = xhr.responseText ? JSON.parse(xhr.responseText) : null;
    //               }
    //               h._handleDone(d);
    //           } else if(xhr.status !== 0 && xhr.status !== 200) {
    //               h._handleAlways();
    //               //console.log(xhr);
    //               if(xhr.responseText.indexOf("{") == 0) {                       
    //                   d = xhr.responseText ? JSON.parse(xhr.responseText) : null;
    //                   if(d && d.error) {
    //                       h._handleFail(d.error);
    //                   } else {
    //                       h._handleFail(null);
    //                   }
    //               } else {
    //                   h._handleFail(xhr.responseText || xhr.statusText);
    //               }
    //           }
    //       }
    //   };

    //   // NOTE: firefox will not send the request if to a different
    //   // origin (CORS) and user/passwd specified in open().
    //   //xhr.open(type, this._url + '/' + path, true, this._username, this._password);
    //   xhr.open(type, this._url + '/' + path, true);
      
    //   // check for network errors.
    //   xhr.onerror = function() {
    //       h._handleAlways();
    //       h._handleFail('Network error contacting server.');
    //   };
      
    //   xhr.withCredentials = true;
      
    //   if(binary) {
    //       xhr.responseType = 'blob';
    //   }
      
    //   // use custom Authorization scheme to prevent browsers (e.g., Chrome) from opening auth dialog.
    //   xhr.setRequestHeader('Authorization', 'WebView ' + btoa(this._username + ':' + this._password));
    //   xhr.send(JSON.stringify(req));
    //   return h;
    // }

    async onDownload(values) {      

      this.setState({'download_loading': true })

      console.log(this.props)

      if (values.fileName.includes('.')){
        this.setState({'error' : 'The file name has an extention or a period. Please remove the period in your filename.' })
        this.setState({'download_loading': false })
      } 
      else if (values.fileName.includes(' ')){
        this.setState({'error' : 'The file name has a space(s) in it. Please remove spaces from your filename.' })
        this.setState({'download_loading': false })
      } 
      else 
      {
        let layer_info = this.props.valueRanges
        let filters = {}

        let selected_dataset_urls = []

        this.props.selectedDatasets.forEach(e => {
            selected_dataset_urls.push(e.gis_services[0].service_url + '/' + e.gis_services[0].layer_name)
        })

        let checked_datasets = Object.keys(this.props.checkedDatasets).filter(key => this.props.checkedDatasets[key] && selected_dataset_urls.includes(key));

        console.log("checked_datasets")
        console.log(checked_datasets)

        checked_datasets.forEach(key => {
            filters[key.split('/').slice(-1)[0]] = layer_info[key] ?? "N/A"
        })

        console.log("this.props.checkedDatasets")
        console.log(this.props.checkedDatasets)

        console.log("layer_info")
        console.log(layer_info)
        console.log("filters")
        console.log(filters)

        console.log(checked_datasets.filter(e => e.includes('/boundary/')))

        if (checked_datasets.filter(e => e.includes('/boundary/')).length > 1){
            this.setState({'error' : 'Only one boundary dataset can be selected per download' })
            this.setState({'download_loading': false })
        } else {
          this.setState({'error' : undefined })

          let base_url = process.env.REACT_APP_API_URL
          let access_token = this.props.access_token

          let encoded_filename = encodeURIComponent(values.fileName)

          const response = await fetch(`${base_url}/query_views/create_shapefile?data=${JSON.stringify(filters)}&filename=${encoded_filename}`, 
              {
                  method: "GET",
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': "Bearer " + access_token
                  },
                }).then(async response => {
                  return await response.blob()
                })
                .then(blob => {
                    var blobURL = URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobURL;
                    anchor.download = values.fileName + '.zip';
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                })
              
          console.log('finished delete')

          this.setState({'download_loading': false })

          this.closeModal()
        }
      }

    }

    async onDownload_wps(values) {
        
        this.setState({'download_loading': true })

        console.log(this.props)

        let layer_info = this.props.valueRanges

        console.log(layer_info)

        let layer_names = Object.keys(layer_info).map(e => {
            return e.split('/').slice(-1)
        })

        console.log("layer_names")
        console.log(layer_names)


        let vector_query = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
        <ows:Identifier>vec:Query</ows:Identifier>
        <wps:DataInputs>
          <wps:Input>
            <ows:Identifier>features</ows:Identifier>
            <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
              <wps:Body>
                <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:boundary="https://sparcal.sdsc.edu/geoserver/boundary">
                  <wfs:Query typeName="boundary:ca_counties"/>
                </wfs:GetFeature>
              </wps:Body>
            </wps:Reference>
          </wps:Input>
          <wps:Input>
            <ows:Identifier>attribute</ows:Identifier>
            <wps:Data>
              <wps:LiteralData>geom</wps:LiteralData>
            </wps:Data>
          </wps:Input>
          <wps:Input>
            <ows:Identifier>filter</ows:Identifier>
            <wps:Data>
              <wps:ComplexData mimeType="text/plain; subtype=cql"><![CDATA[name='Yolo']]></wps:ComplexData>
            </wps:Data>
          </wps:Input>
        </wps:DataInputs>
        <wps:ResponseForm>
          <wps:RawDataOutput mimeType="application/json">
            <ows:Identifier>result</ows:Identifier>
          </wps:RawDataOutput>
        </wps:ResponseForm>
      </wps:Execute>`


      var config = {
        headers: {
            'Content-Type': 'text/plain',
            "Accept": "application/json"
        },
    };

      // var requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: vector_query,
      //   redirect: 'follow'
      // };
      // fetch('https://sparcal.sdsc.edu/geoserver' + "/wps", requestOptions)
      //   .then(response => response.text())
      //   .then(result => {
      //     // document.getElementById("result").innerText = result;
      //     console.log(result);
      //     })
      //   .catch(error => console.log('error', error));

      console.log('did it hit this?')
       await axios.post('https://sparcal.sdsc.edu/geoserver/wps', vector_query, config)
            .then(res => {
                console.log("res")
                console.log(res)
                console.log(res.data)
                const json_str = JSON.stringify(res.data.features);
                var blob = new Blob([json_str], {type: "application/json"});

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = values.fileName + '.json';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Error:', error));

          console.log('aslkdjfa;sljkd')
        // let datasetInfo = this.props.getQueryData()

        // console.log("datasetInfo sddsd")
        // console.log(datasetInfo)



        // const parser = new DOMParser();
        // const xmlDoc = parser.parseFromString(data, 'text/xml');

        // // Extract the extent information from the parsed XML
        // const lowerCorner = xmlDoc.getElementsByTagName('gml:lowerCorner')[0].textContent;
        // const upperCorner = xmlDoc.getElementsByTagName('gml:upperCorner')[0].textContent;

        // // Convert the extent coordinates to an array of numbers
        // const [minX, minY] = lowerCorner.split(' ');
        // const [maxX, maxY] = upperCorner.split(' ');

        // console.log(minX)
        // console.log(minY)
        // console.log(maxX)
        // console.log(maxY)

        // let extent = this.props.map.getView().calculateExtent(this.props.map.getSize())
        // console.log("extent")
        // console.log(extent)
      //   <ows:BoundingBox crs="http://www.opengis.net/gml/srs/epsg.xml#3310">
      //   <ows:LowerCorner>-265206.1841009631 -346460.2334171161</ows:LowerCorner>
      //   <ows:UpperCorner>58793.81589903688 37809.766582883894</ows:UpperCorner>
      // </ows:BoundingBox>

  //     <wps:Reference mimeType="text/xml" xlink:href="http://${selecteFeatureGisService.service_type.toLowerCase()}/wfs" method="POST">
  //     <wps:Body>
  //         <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:${selecteFeatureGisService.layer_name.split(':')[0]}="${selecteFeatureGisService.service_url.replace('/wms', "")}">
  //             <wfs:Query typeName="${selecteFeatureGisService.layer_name}"/>
  //         </wfs:GetFeature>
  //     </wps:Body>
  // </wps:Reference>

      // layer_names.forEach(async layer_name => {

        let xmlBodyClip = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
        <ows:Identifier>ras:CropCoverage</ows:Identifier>
        <wps:DataInputs>
          <wps:Input>
            <ows:Identifier>coverage</ows:Identifier>
            <wps:Reference mimeType="image/tiff" xlink:href="http://geoserver/wcs" method="POST">
              <wps:Body>
                <wcs:GetCoverage service="WCS" version="1.1.1">
                  <ows:Identifier>rrk_cc:wildlife_species_richness</ows:Identifier>
                  <wcs:DomainSubset>
                    <ows:BoundingBox crs="http://www.opengis.net/gml/srs/epsg.xml#3310">
                      <ows:LowerCorner>-265169.99999999814 -346440.0</ows:LowerCorner>
                      <ows:UpperCorner>58770.00000000186 37770.0</ows:UpperCorner>
                    </ows:BoundingBox>
                  </wcs:DomainSubset>
                  <wcs:Output format="image/tiff"/>
                </wcs:GetCoverage>
              </wps:Body>
            </wps:Reference>
          </wps:Input>
          <wps:Input>
            <ows:Identifier>cropShape</ows:Identifier>
            <wps:Reference mimeType="text/xml; subtype=gml/3.1.1" xlink:href="http://geoserver/wps" method="POST">
              <wps:Body>
                <wps:Execute version="1.0.0" service="WPS">
                  <ows:Identifier/>
                  <wps:DataInputs/>
                  <wps:ResponseForm/>
                </wps:Execute>
              </wps:Body>
            </wps:Reference>
          </wps:Input>
        </wps:DataInputs>
        <wps:ResponseForm>
          <wps:RawDataOutput mimeType="image/tiff">
            <ows:Identifier>result</ows:Identifier>
          </wps:RawDataOutput>
        </wps:ResponseForm>
      </wps:Execute>`

      //   let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
      //   <ows:Identifier>gs:PolygonExtraction</ows:Identifier>
      //   <wps:DataInputs>
      //     <wps:Input>
      //       <ows:Identifier>data</ows:Identifier>
      //       <wps:Reference mimeType="image/tiff" xlink:href="http://geoserver/wcs" method="POST">
      //         <wps:Body>
      //           <wcs:GetCoverage service="WCS" version="1.1.1">
      //             <ows:Identifier>${layer_name}</ows:Identifier>
      //             <wcs:DomainSubset>
      //             <ows:BoundingBox crs="http://www.opengis.net/gml/srs/epsg.xml#3310">
      //               <ows:LowerCorner>-265206.1841009631 -346460.2334171161</ows:LowerCorner>
      //               <ows:UpperCorner>58793.81589903688 37809.766582883894</ows:UpperCorner>
      //             </ows:BoundingBox>
      //             </wcs:DomainSubset>
      //             <wcs:Output format="image/tiff"/>
      //           </wcs:GetCoverage>
      //         </wps:Body>
      //       </wps:Reference>
      //     </wps:Input>
      //       <wps:Input>
      //         <ows:Identifier>features</ows:Identifier>
      //         <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
      //           <wps:Body>
      //             <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:boundary="https://sparcal.sdsc.edu/geoserver/boundary">
      //               <wfs:Query typeName="boundary:ca_counties"/>
      //             </wfs:GetFeature>
      //           </wps:Body>
      //         </wps:Reference>
      //       </wps:Input>
      //       <wps:Input>
      //         <ows:Identifier>clip</ows:Identifier>
      //         <wps:Data>
      //           <wps:ComplexData mimeType="text/xml; subtype=gml/3.1.1"><![CDATA[name IN ('Yolo')]]></wps:ComplexData>
      //         </wps:Data>
      //       </wps:Input>
      //     <wps:Input>
      //       <ows:Identifier>nodata</ows:Identifier>
      //       <wps:Data>
      //         <wps:LiteralData>-3.4028230607370965e+38</wps:LiteralData>
      //       </wps:Data>
      //     </wps:Input>
      //     <wps:Input>
      //       <ows:Identifier>ranges</ows:Identifier>
      //       <wps:Data>
      //         <wps:LiteralData>[1000;9057.949]</wps:LiteralData>
      //       </wps:Data>
      //     </wps:Input>
      //   </wps:DataInputs>
      //   <wps:ResponseForm>
      //     <wps:RawDataOutput mimeType="application/json">
      //       <ows:Identifier>result</ows:Identifier>
      //     </wps:RawDataOutput>
      //   </wps:ResponseForm>
      // </wps:Execute>`

      let xmlBodyStr1 = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
      <ows:Identifier>gs:PolygonExtraction</ows:Identifier>
      <wps:DataInputs>
        <wps:Input>
          <ows:Identifier>data</ows:Identifier>
          <wps:Reference mimeType="image/tiff" xlink:href="http://geoserver/wcs" method="POST">
            <wps:Body>
              <wcs:GetCoverage service="WCS" version="1.1.1">
                <ows:Identifier>rrk_cc:Vulner_PM25_Severe_2022</ows:Identifier>
                <wcs:DomainSubset>
                  <ows:BoundingBox crs="http://www.opengis.net/gml/srs/epsg.xml#3310">
                    <ows:LowerCorner>-265206.1841009631 -346460.2334171161</ows:LowerCorner>
                    <ows:UpperCorner>58793.81589903688 37809.766582883894</ows:UpperCorner>
                  </ows:BoundingBox>
                </wcs:DomainSubset>
                <wcs:Output format="image/tiff"/>
              </wcs:GetCoverage>
            </wps:Body>
          </wps:Reference>
        </wps:Input>       
      </wps:DataInputs>
      <wps:ResponseForm>
        <wps:RawDataOutput mimeType="application/json">
          <ows:Identifier>result</ows:Identifier>
        </wps:RawDataOutput>
      </wps:ResponseForm>
    </wps:Execute>`

//     <wps:Input>
//     <ows:Identifier>ranges</ows:Identifier>
//     <wps:Data>
//       <wps:LiteralData>[1000;9057.949]</wps:LiteralData>
//     </wps:Data>
//   </wps:Input>

        // var config = {
        //     headers: {
        //         'Content-Type': 'text/plain',
        //         // "Access-Control-Allow-Origin": "*",
        //         "Accept": "application/json"
        //     },
        // };

        // await axios.post('https://sparcal.sdsc.edu/geoserver/wps', xmlBodyStr1, config)
        //     .then(res => {
        //         console.log("res")
        //         console.log(res)
        //         console.log(res.data)
        //         const json_str = JSON.stringify(res.data.features);
        //         var blob = new Blob([json_str], {type: "application/json"});

        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = values.fileName + '.tiff';
        //         document.body.appendChild(a);
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     })
        //     .catch((error) => console.error('Error:', error));

        // let datasetInfo = this.props.getQueryData()

        // console.log("datasetInfo sddsd")
        // console.log(datasetInfo)

      // })


        // let base_url = process.env.REACT_APP_API_URL        

        // const response = await fetch(`${base_url}/query_views/download?data=${JSON.stringify(datasetInfo)}&fileName=${encodeURIComponent(values.fileName)}`, 
        //     {
        //         method: "GET",
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Authorization': "Bearer " + this.props.access_token
        //         }
        //     }).then((response) => response.blob())
        //     .then((blob) => {
        //         const url = window.URL.createObjectURL(new Blob([blob]));
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = values.fileName + '.zip';
        //         document.body.appendChild(a);
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     })
        //     .catch((error) => console.error('Error:', error));

        this.setState({'download_loading': false })
        
        this.closeModal()
    }

    render() {
        let rootStyle = this.props.style;

        console.log("this.state.hover")
        console.log(this.state.hover)

        if (rootStyle === undefined) {
            rootStyle = {
                ...rootStyle,
                position: 'absolute',
                top: '96px',
                left: '7px',
                padding: '1px 4px 1px 4px',
                borderRadius: '6px',
                backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
                border: '3px solid rgba(255, 255, 255, 0.72)',
            };
        }
        
        return (
            <div>
                <div style={rootStyle} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                    <Tooltip title={'Download'} placement="right" >
                        <DownloadOutlined onClick={this.openModal} style={{color: 'white'}}/>
                    </Tooltip>
                </div>
                <Modal title="Download Data as Shapefile"
                    open={this.state.visible}
                    onCancel={this.closeModal}
                    destroyOnClose={true}
                    // onOk={this.onDownload}
                    confirmLoading={this.state.download_loading}
                    footer={
                        this.state.download_loading
                        ?
                            <Button form="download_data" type="primary" htmlType="submit" disabled>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin/>} /> 
                                <span style={{paddingLeft: 10}}>Download</span>
                            </Button>
                        :                         
                        <Button form="download_data" type="primary" htmlType="submit">
                            Download
                        </Button>
                    }
                    width={700}
                    bodyStyle={{
                        maxHeight: 350
                    }}>
                <div>
                  {this.state.error ? <div style={{color: 'red', marginBottom: '10px' }}>{this.state.error}</div> : null}
                    <Form
                        name="download_data"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={(values) => this.onDownload(values)}                        
                        scrollToFirstError="true">
                        <Form.Item
                            style={{marginBottom: '4px'}}
                            label="File Name of Download"
                            name="fileName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your preferred file name',
                                },
                            ]}
                            >
                            <Input />
                        </Form.Item>
                        <div>
                            <span style={{fontSize: "10px"}}>* Do not add an extention to your file name</span>
                        </div>
                        {/* <div style={{marginTop: '10px'}}>
                            <span>Reminder: This is a prototype and data available here is only provided for the purpose of gathering user feedback. Please acknowledge that you understand and will not use this data.</span>
                        </div> */}
                        {/* <Form.Item
                            style={{marginTop: 20}}
                            name='reminder'
                            rules={[ 
                                {
                                required: true,
                                message: 'Please check the box stating you understand the data is only for user feedback use',
                            }
                            ]}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                    I understand
                            </Checkbox>
                        </Form.Item> */}
                       
                        {/* <Form.Item style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
            </Modal>
            </div>
        );
    }   
}


export default ForestDataMapDownload;