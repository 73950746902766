import React from 'react';
import ReactDOM from 'react-dom';
import OlOverlay from 'ol/Overlay';
import './forest_data_map_layer_popover.css';
import OlLayerTile from "ol/layer/Tile";
import { NoneType } from 'ol/expr/expression';
import { CloseOutlined } from '@ant-design/icons';
import { PinchZoom } from 'ol/interaction';

// import { getLayerConfig } from './utils';

const syncfetch = require('sync-fetch')

class ForestDataMapPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feature_attributes: [],
            selectedDatasets: props.selectedDatasets
        }
        this.handleClose = this.handleClose.bind(this);
        this.getLayerConfig = this.getLayerConfig.bind(this);
    }

    handleClose(event) {
        this.popup.setPosition(undefined);
        console.log('handle close')
        event.target.blur();
        return false;
    }


    componentDidMount() {
        // setup popup
        this.popup = new OlOverlay({
            element: ReactDOM.findDOMNode(this).querySelector('#popup')
        });

        let map = this.props.map;
        if (map === undefined) {
            console.log("in popover: no map was given");
            map = this.props.mapRef.current.getMap()
        }

        let layers = this.props.layers;
        if (layers === undefined) {
            layers = this.props.mapRef.current.getLayers()
        }

        map.addOverlay(this.popup);

        var thisObject = this;
        map.on('singleclick', async evt => {
            layers = [];
            map.getLayers().forEach(function (layer) {
                console.log(layer)
                console.log(layer instanceof OlLayerTile)
                if (layer.getProperties().service_url && layer.getProperties().layer_name) {
                    layers.push(layer);
                }
            });
            
            var feature_attributes = [];
            const viewResolution = map.getView().getResolution();
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].getVisible()) {
                    let item = this.props.selectedDatasets.filter(e => this.props.getItemKey(e) === 
                        layers[i].getProperties().service_url + '/' + layers[i].getProperties().layer_name)[0]

                    let gis_service = this.props.getGisService(item)
                    if(gis_service.service_type === 'ArcGIS'){
                        console.log(evt.coordinate)
                        console.log(evt)

                        let pixel = evt.pixel
                    
                        const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
                            return feature;
                        }, {
                            layerFilter: function(layer) {
                                return layer.getProperties().service_url + '/' + layer.getProperties().layer_name 
                                    === layers[i].getProperties().service_url + '/' + layers[i].getProperties().layer_name;
                            }
                        });

                        if (feature) {
                            console.log(`${gis_service.service_url}?f=pjson`)

                            let information = await fetch(`${gis_service.service_url}?f=pjson`)
                                    .then((response) => {
                                        return response.json()
                                    }).catch((error) => console.error(error))

                            let fieldInfo = information.fields
                            
                            feature_attributes.push({
                                url: gis_service.service_url,
                                layer_name: gis_service.layer_name,
                                features: feature.values_,
                                fieldInfo: fieldInfo
                            })
                            thisObject.setState({
                                feature_attributes
                            });
                            thisObject.popup.setPosition(evt.coordinate);
                        }
                    } else {
                        const urls = layers[i].getSource().getUrls();
                        const layer_name = layers[i].getSource().getParams().LAYERS;
                        const url = layers[i].getSource().getFeatureInfoUrl(
                            evt.coordinate,
                            viewResolution,
                            map.getView().getProjection(),
                            {'INFO_FORMAT': 'application/json'}
                        );
                        if (url) {
                            var responseJSON = syncfetch(url).json();
                            if (responseJSON.features &&
                                responseJSON.features.length > 0 &&
                                responseJSON.features[0].properties) {

                                let filter = this.props.valueRanges[this.props.getItemKey(item)]

                                let in_filter = true
                                if(filter !== undefined){

                                    let feature = responseJSON.features[0].properties

                                    console.log("feature")
                                    console.log(feature)

                                    if (item.data_type == 'raster') {
                                        let pixel_type = Object.keys(feature)[0]
                                        console.log(pixel_type)
                                        let pixel_value = feature[pixel_type]
    
                                        if(pixel_value < filter[0] || pixel_value > filter[1]){
                                            in_filter = false
                                        }
                                    } else {
                                        in_filter = true
                                    }

                                }
                                
                                if(in_filter) {
                                    feature_attributes.push({
                                        url: urls.toString(),
                                        layer_name,
                                        features: responseJSON.features[0].properties
                                    })
                                    thisObject.setState({
                                        feature_attributes
                                    });
                                    thisObject.popup.setPosition(evt.coordinate);
                                }                                
                            }
                        }
                    }
                }
            }

            if (thisObject.state.feature_attributes.length == 0) {
               document.getElementById('popup-closer').click()
            }
        });

    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDatasets.length !== this.state.selectedDatasets.length) {
            this.setState({
                selectedDatasets: [...this.props.selectedDatasets]
            });
        }
    }


    getValueRange(item) {
        for (const [key, value] of Object.entries(this.props.valueRanges)) {
            if (item.gis_services[0].service_url + '/' + item.gis_services[0].layer_name_name === key) {
                return value;
            }
        }

        const layerConfig = this.getLayerConfig(item.url, item.layer_name);
        if (layerConfig.type === 'raster') {
            return [layerConfig.min, layerConfig.max];
        }
        return [-10000, 10000];
    }

    getLayerConfig(url, layer) {
        for (let i = 0; i < this.state.selectedDatasets.length; i++) {
            let dataset = this.state.selectedDatasets[i];
            if (dataset.mapping[0].layer === layer && dataset.mapping[0].url === url) {
                return dataset.mapping[0];
            }
        }
        return null;
    }
    

    render() {
        let string = `<table style="table-layout: fixed;"><tbody>`;
        let displayed = false;
        this.state.feature_attributes.map((item, i) => {
            let dataset = this.state.selectedDatasets.filter(e => this.props.getItemKey(e) === (item.url + '/' + item.layer_name))[0]

            if (dataset !== null && dataset !== undefined)
            {
                if (dataset.data_type === 'raster') {
                    for (let [, value] of Object.entries(item.features)) {
                        string += `<tr><td style="word-wrap:break-word; font-weight: bold; padding-right: 10pt; text-align: left;">${dataset.name}:</td>`;
                        string += `<td style="white-space: nowrap; color: darkgreen;">${value}</td></tr>\n`;
                        displayed = true;
                        break;
                    }
                } else if (dataset.data_type === 'vector') {
                    
                    string += `<tr><td style="font-weight: bold; text-wrap: wrap; padding-right: 10pt; text-align: left;">${dataset.name}:</td><td></td></tr>`;
                    string += `<tr><td colspan="2" style="padding-left: 10pt;"><table>`

                    let values = item.features
                    let fieldInfo = item.fieldInfo

                    Object.entries(values).forEach((obj) => {
                        let columnName = obj[0]
                        let value = obj[1]

                        if(fieldInfo !== undefined){
                            let info = fieldInfo.filter(e => e.modelName === columnName)
    
                            if(info.length > 0){
                                info = info[0]
                                columnName = info.alias
                                if (info.type === "esriFieldTypeDate" && value){
                                    value = new Date(value).toLocaleDateString('en-US')
                                }                                
    
                                if(info.domain && info.domain.codedValues){
                                    let label = info.domain.codedValues.filter(e => e.code === value)[0]
                                    if(label){
                                        value = label.name
                                    }
                                }
                            }
                        }

                        if(typeof(value) !== 'object'){
                            string += `<tr><td style="font-weight: normal; padding-right: 10pt; text-align: left">${columnName}:</td><td style="white-space: nowrap; color: darkgreen;">${value ? value : ''}</td></tr>`;
                            displayed = true;
                        } 
                        

                    })
                    string += `</td></td></table>`
                }
        }

            return null;
        });
        string += "</tbody></table>";

        if (!displayed && this.popup) {
            this.popup.setPosition(undefined);
        }

        return (
            <div>
                <div id="popup" className="ol-popup">
                    <a href="#" id="popup-closer" className="ol-popup-closer" onClick={this.handleClose}></a>
                    <div id="popup-content">
                        <div style={{
                            padding: '14px 10pt 10pt 5pt',
                            fontSize: '9pt',
                            whiteSpace: 'nowrap',
                            maxHeight: 300,
                            // maxWidth: 480,
                            overflow: 'scroll',
                        }}>
                            <div dangerouslySetInnerHTML={{__html: string}}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ForestDataMapPopover;



